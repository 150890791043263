<!-- header -->
<div class="uk-section uk-section-small uk-margin-large-top">
    <div class="uk-container">
        <h1 class="uk-heading-small uk-text-center">SIGN-IN</h1>
        <div class="uk-margin">
            <app-sign-in-form *ngIf="!isUserConfirmationRequired"
                (passwordResetRequired)="onPasswordResetRequired($event)"
                (UserConfirmationRequired)="onUserConfirmationRequired($event)"></app-sign-in-form>
            <app-user-confirmation-form *ngIf="isUserConfirmationRequired" [email]="email" [password]="password">
            </app-user-confirmation-form>
        </div>
        <div *ngIf="!isUserConfirmationRequired" class="uk-margin">
            <ul class="uk-list uk-text-center">
                <li>
                    <a routerLink="/sign-up" class="uk-button uk-button-text">SIGN-UP</a>
                </li>
                <li>
                    <a routerLink="/reset-password" class="uk-button uk-button-text">RESET PASSWORD</a>
                </li>
            </ul>
        </div>
    </div>
</div>