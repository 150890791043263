<div *ngIf="this.failureMessages">
    <div *ngFor="let failureMessage of failureMessages" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>{{failureMessage}}</p>
    </div>
</div>

<form class="uk-form uk-grid-small uk-child-width-1-1" (ngSubmit)="onSubmit()" #signUpForm="ngForm" uk-grid>
    <div>
        <input class="uk-input uk-border-rounded" type="email" placeholder="Email" [(ngModel)]="email"
            [ngClass]="{'uk-form-danger': emailInput.invalid && (emailInput.dirty || emailInput.touched)}"
            name="emailInput" #emailInput="ngModel" ngModel>
    </div>
    <div>
        <input class="uk-input uk-border-rounded" type="password" placeholder="New Password" [(ngModel)]="password"
            [ngClass]="{'uk-form-danger': passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)}"
            name="passwordInput" #passwordInput="ngModel" ngModel>
    </div>
    <div>
        <input class="uk-input uk-border-rounded" type="password" placeholder="Confirm Password"
            [(ngModel)]="confirmPassword"
            [ngClass]="{'uk-form-danger': confirmPasswordInput.invalid && (confirmPasswordInput.dirty || confirmPasswordInput.touched)}"
            name="confirmPasswordInput" #confirmPasswordInput="ngModel" ngModel>
    </div>
    <div>
        <input class="uk-input uk-border-rounded uk-button uk-button-primary" value="LET'S GO!" type="submit">
    </div>
</form>