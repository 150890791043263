import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { AuthService } from '../auth.service'

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  email: string = ''
  errorMessages: string[] = []
  @Output() resetPasswordCodeDelivery = new EventEmitter<any>()
  hasSucceeded: boolean = false
  isLoading: boolean = false

  constructor (
    private readonly authService: AuthService
  ) { }

  ngOnInit (): void {
  }

  onError (err: any): void {
    this.hasSucceeded = false
    this.errorMessages.push(err.message)
  }

  onFinally (): void {
    this.isLoading = false
  }

  onReset (): void {
    this.email = ''
    this.hasSucceeded = false
    this.isLoading = false
    this.errorMessages = []
  }

  onResponse (res: any): void {
    this.hasSucceeded = true
    this.resetPasswordCodeDelivery.emit(this.email)
  }

  onSubmit (): void {
    this.authService
      .forgotPassword$(this.email)
      .subscribe(
        res => this.onResponse(res),
        err => this.onError(err),
        () => this.onFinally()
      )
  }
}
