<div class="uk-grid-collapse uk-height-viewport" uk-grid>
  <div
    class="uk-width-1-2@m uk-width-2-3@l uk-visible@m uk-background-cover uk-background-center-right uk-background-no-repeat uk-height-viewport"
    style="background-image:url('/assets/images/hero@2x.png');">
  </div>
  <div class="uk-width-1-2@m uk-width-1-3@l uk-box-shadow-small">
    <div class="uk-flex-center" uk-grid>
      <div class="uk-width-large">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="uk-section uk-section-xsmall">
      <div class="uk-container">
        <div class="uk-flex-center uk-grid-small uk-text-small" uk-grid>
          <div>
            <a class="uk-inline uk-button uk-button-text uk-text-muted" href="https://dev.fieldt.rip/terms"
              target="_blank">TERMS</a>
          </div>
          <div>
            <a class="uk-inline uk-button uk-button-text uk-text-muted" href="https://dev.fieldt.rip/billing"
              target="_blank">BILLING</a>
          </div>
          <div>
            <a class="uk-inline uk-button uk-button-text uk-text-muted" href="https://dev.fieldt.rip/privacy"
              target="_blank">PRIVACY</a>
          </div>
        </div>
        <div class="uk-text-center uk-text-muted uk-margin-medium-top uk-text-small">
          Copyright © <a href="https://fieldt.rip">fieldt.rip</a>. All Rights Reserved.</div>
      </div>
    </div>
  </div>
</div>