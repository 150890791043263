import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl = '/'

  confirmForgotPassword$ (username: string, code: string, password: string): Observable<void> {
    return of<any>()
  }

  confirmSignUp$ (username: string, code: string, options?: any): Observable<any> {
    return of<any>()
  }

  currentUserAttributes$ (): Observable<any> {
    return of<any>()
  }

  currentUser$ (): Observable<any> {
    return of<any>()
  }

  forgotPassword$ (username: string): Observable<any> {
    return of<any>()
  }

  forgotPasswordSubmit$ (username: string, code: string, password: string, metadata?: { [key: string]: string }): Observable<any> {
    return of<any>()
  }

  isLoggedIn$ (): Observable<any> {
    return of<any>()
  }

  jwtToken$ (): Observable<any> {
    return of<any>()
  }

  resendSignUp$ (username: string): Observable<any> {
    return of<any>()
  }

  signIn$ (username: string, password: string): Observable<any> {
    return of<any>()
  }

  signOut$ (): Observable<any> {
    return of<any>()
  }

  signUp$ (username: string, password: string, attributes?: any): Observable<any> {
    return of<any>()
  }

  updateCurrentUserAttributes$ (attributes: any): Observable<any> {
    return of<any>()
  }

  updateCurrentUserPassword$ (oldPassword: string, newPassword: string): Observable<any> {
    return of<any>()
  }

  verifyCurrentUserAttributes (attribute: string, code: string): Observable<any> {
    return of<any>()
  }
}
