import { Component } from '@angular/core'

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss']
})
export class SignUpPageComponent {
  accountType: string = ''
  email: string = ''
  password: string = ''
  isConfirmationCodeDelivered: boolean = false

  onConfirmationCodeDelivery (res: any): void {
    this.email = res.email
    this.password = res.password
    this.isConfirmationCodeDelivered = true
  }
}
