<div class="uk-section uk-section-small uk-margin-large-top">
    <div class="uk-container">
        <h1 class="uk-heading-small uk-text-center">RESET PASSWORD</h1>
        <div>
            <app-reset-password-form *ngIf="!isResetPasswordCodeDelivered"
                (resetPasswordCodeDelivery)="onResetPasswordCodeDelivery($event)"></app-reset-password-form>
            <app-reset-password-confirmation-form [email]="email" *ngIf="isResetPasswordCodeDelivered">
            </app-reset-password-confirmation-form>
        </div>
        <div class="uk-margin-medium-top">
            <ul class="uk-list uk-text-center">
                <li><a routerLink="/sign-in" class="uk-button uk-button-text">Sign-In</a></li>
                <li><a routerLink="/sign-up" class="uk-button uk-button-text">Sign-Up</a></li>
            </ul>
        </div>
    </div>
</div>