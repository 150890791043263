<div class="uk-section uk-section-small uk-margin-large-top">
    <div class="uk-container">
        <h1 class="uk-heading-small uk-text-center">SIGN-UP</h1>
        <div>
            <app-sign-up-form *ngIf="!isConfirmationCodeDelivered"
                (confirmationCodeDelivery)="onConfirmationCodeDelivery($event)"></app-sign-up-form>
            <!-- <app-user-confirmation-form *ngIf="isConfirmationCodeDelivered" [email]="email" [password]="password">
            </app-user-confirmation-form> -->
        </div>
        <div class="uk-margin-medium-top" *ngIf="!isConfirmationCodeDelivered">
            <ul class="uk-list uk-text-center">
                <li><a routerLink="/sign-in" class="uk-button uk-button-text">SIGN-IN</a></li>
                <li><a routerLink="/reset-password" class="uk-button uk-button-text">RESET PASSWORD</a></li>
            </ul>
        </div>

    </div>
</div>