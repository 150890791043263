import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-confirm-sign-up-form',
  templateUrl: './confirm-sign-up-form.component.html',
  styleUrls: ['./confirm-sign-up-form.component.scss']
})
export class ConfirmSignUpFormComponent implements OnInit {
  ngOnInit (): void {
  }
}
