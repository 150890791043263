// modules
import { Component, EventEmitter, Input, Output } from '@angular/core'

// services
import { AuthService } from '../auth.service'

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent {
  @Input() accountType: 'EDUCATOR' | 'FAMILY_MEMBER' = 'EDUCATOR'
  email: string = ''
  password: string = ''
  confirmPassword: string = ''
  failureMessages: string[] = []
  @Output() confirmationCodeDelivery = new EventEmitter<any>()

  constructor (private readonly authService: AuthService) { }

  onSubmit (): void {
    this.authService
      .signUp$(
        this.email,
        this.password, {
          'custom:type': this.accountType,
          'custom:requirements': ['CREATE_CUSTOMER', 'ADD_PAYMENT_METHOD', 'CREATE_SUBSCRIPTION', 'CREATE_ACCOUNT', 'ACCEPT_STRIPE_TOS', 'SELECT_BUSINESS_TYPE'].join(',')
        })
      .subscribe(
        (res: any) => this.onSuccess(res),
        (err: any) => this.onFailure(err)
      )
  }

  onFailure (err: any): void {
    this.failureMessages.push(err.message)
  }

  onSuccess (res: any): void {
    this.confirmationCodeDelivery.emit({
      email: this.email,
      password: this.password
    })
  }
}
