<div *ngIf="this.errorMessages">
    <div *ngFor="let errorMessage of errorMessages" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>{{errorMessage}}</p>
    </div>
</div>

<form class="uk-form uk-grid-small uk-child-width-1-1" (ngSubmit)="onSubmit()" #signInForm="ngForm" uk-grid>
    <div>
        <input class="uk-input uk-border-rounded" type="email" placeholder="Email" [(ngModel)]="email"
            [ngClass]="{'uk-form-danger': emailInput.invalid && (emailInput.dirty || emailInput.touched)}"
            name="emailInput" #emailInput="ngModel" required email ngModel>
    </div>
    <div>
        <input class="uk-input uk-border-rounded" type="password" placeholder="Password" [(ngModel)]="password"
            [ngClass]="{'uk-form-danger': passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)}"
            name="passwordInput" #passwordInput="ngModel" required minlength="8" ngModel>
    </div>
    <div>
        <button [disabled]="isLoading || (emailInput.invalid || passwordInput.invalid)" class="uk-input uk-border-rounded uk-button uk-button-primary" type="submit">
            <span *ngIf="!isLoading">Let's Go!</span>
            <span *ngIf="isLoading" uk-spinner="ratio:0.5;"></span>
        </button>
    </div>
</form>