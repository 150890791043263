// modules
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// components
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component'
import { SignInPageComponent } from './sign-in-page/sign-in-page.component'
import { SignUpPageComponent } from './sign-up-page/sign-up-page.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  },
  {
    path: 'forgot-password',
    component: ResetPasswordPageComponent,
    data: {
      title: 'reset password | fieldt.rip'
    }
  },
  {
    path: 'sign-up',
    component: SignUpPageComponent,
    data: {
      title: 'sign-up | fieldt.rip'
    }
  },
  {
    path: 'sign-in',
    component: SignInPageComponent,
    data: {
      title: 'sign-in | fieldt.rip'
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
