import { Component } from '@angular/core'

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent {
  email: string = ''
  password: string = ''
  isUserConfirmationRequired: boolean = false
  isPasswordResetRequired: boolean = false

  onPasswordResetRequired (res: any): void {
    this.email = res.email
    this.isUserConfirmationRequired = true
  }

  onUserConfirmationRequired (res: any): void {
    this.email = res.email
    this.password = res.password
    this.isUserConfirmationRequired = true
  }
}
