import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { AuthService } from '../auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {
  email: string = ''
  password: string = ''
  @Output() UserConfirmationRequired: EventEmitter<any> = new EventEmitter<any>()
  @Output() passwordResetRequired: EventEmitter<any> = new EventEmitter<any>()
  isLoading = false
  errorMessages: string[] = []

  constructor (
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  ngOnInit (): void {
    this.authService
      .isLoggedIn$()
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.router.navigate(['/']).finally(console.log)
        }
      })
  }

  onError (err: any): void {
    this.isLoading = false
    switch (err.code) {
      case 'UserNotConfirmedException':
        this.UserConfirmationRequired
          .emit({
            email: this.email,
            password: this.password
          })
        break
      case 'PasswordResetRequiredException':
        this.passwordResetRequired
          .emit({
            email: this.email,
            password: this.password
          })
        break
      default:
        this.errorMessages.push(err.message)
    }
  }

  onFinally (): void {
    this.stopLoading()
  }

  onResponse (user: any): void {
    this.router
      .navigate([this.authService.redirectUrl], {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      })
      .then(console.log)
      .catch(console.error)
      .finally(console.log)
  }

  onSubmit (): void {
    this.startLoading()
    this.authService
      .signIn$(this.email, this.password)
      .subscribe(
        res => this.onResponse(res),
        err => this.onError(err),
        () => this.onFinally()
      )
  }

  startLoading (): void {
    this.isLoading = true
  }

  stopLoading (): void {
    this.isLoading = false
  }
}
