<div *ngIf="this.errorMessages">
    <div *ngFor="let errorMessage of errorMessages" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>{{errorMessage}}</p>
    </div>
</div>

<form class="uk-form uk-grid-small uk-child-width-1-1" (ngSubmit)="onSubmit()" #resetPasswordForm="ngForm" uk-grid>
    <div>
        <input class="uk-input uk-border-rounded" type="email" placeholder="Email" [(ngModel)]="email"
            [ngClass]="{'uk-form-danger': emailInput.invalid && (emailInput.dirty || emailInput.touched)}"
            name="emailInput" #emailInput="ngModel" required email ngModel>
    </div>
    <div>
        <div class="uk-child-width-1-2" uk-grid>
            <div>
                <button [disabled]="!emailInput.dirty"
                    class="uk-button uk-width-expand uk-border-rounded uk-button-default uk-margin-small-right"
                    type="button" (click)="onReset()">RESET</button>
            </div>
            <div>
                <button [disabled]="isLoading || emailInput.pristine || emailInput.invalid"
                    [class]="{ 'uk-button-secondary': hasSucceeded }"
                    class="uk-border-rounded uk-button uk-width-expand uk-button-primary" type="submit">
                    <span *ngIf="!isLoading && !hasSucceeded">SUBMIT</span>
                    <span *ngIf="isLoading" uk-spinner="ratio:0.5;"></span>
                    <span *ngIf="hasSucceeded"><span class="uk-margin-small-right" uk-icon="check"></span>SUCCESS</span>
                </button>
            </div>
        </div>
    </div>
</form>