// modules
import { AppRoutingModule } from './app-routing.module'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'

// components
import { AppComponent } from './app.component'
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component'
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component'
import { SignInFormComponent } from './sign-in-form/sign-in-form.component'
import { SignInPageComponent } from './sign-in-page/sign-in-page.component'
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component'
import { SignUpPageComponent } from './sign-up-page/sign-up-page.component'

// services
import { AuthService } from './auth.service'
import { StorageService } from './storage.service'
import { ConfirmSignUpFormComponent } from './confirm-sign-up-form/confirm-sign-up-form.component'
@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordFormComponent,
    ResetPasswordPageComponent,
    SignInFormComponent,
    SignInPageComponent,
    SignUpFormComponent,
    SignUpPageComponent,
    ConfirmSignUpFormComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule
  ],
  providers: [
    AuthService,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
