import { Component } from '@angular/core'

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent {
  email: string = ''
  isResetPasswordCodeDelivered: boolean = false

  onResetPasswordCodeDelivery (email: string): void {
    this.email = email
    this.isResetPasswordCodeDelivered = true
  }
}
